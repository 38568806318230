@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}



.login-btn{
  background-color:"#fb641b";color:"white";
}
.policy{
  font-size: 0.65rem;
  margin-top: 2rem;
 
}
.lk{
  font-size: 0.65rem;
  color:#0000ff !important;
  cursor:pointer;

}

/* input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: white !important;
  color: !important;
} */


.otpContainer{
  display:flex;
  gap:2rem;
  justify-content: center;


}
.otpInput{
  border:#999 1px solid;
  width:3rem;
  height:3rem;
  text-align: center;
}
.loader{
 
  display:flex;
  flex-direction: row;
 
}
.loading{
  width:20px;
  height:20px;
  margin:2px auto;
  padding:1px auto;
  background:#003C7E; 
  border-radius: 50%;
  animation: loadingAnimation 3s infinite;
}
@keyframes loadingAnimation {
  0% {
    opacity:0;
    transform:scale(0);
   
  }
  25%{
    opacity:1;
    transform:scale(1);
 
  }
  100% {
    opacity:0;
    transform:scale(0);

  }
 
}

   @keyframes spinner {
        0% {
          transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }
        100% {
          transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
      }
      .spin::before {
        animation: 1.5s linear infinite spinner;
        animation-play-state: inherit;
        border: solid 5px #cfd0d1;
        border-bottom-color: #003C7E;
        border-radius: 50%;
        content: "";
        height: 50px;
        width: 50px;
        position: absolute;
        top: 15vh;
        left: 25vw;
        transform: translate3d(-50%, -50%, 0);
        will-change: transform;
        
      }


      .icon-stack {
        position: relative;
        display: inline-block;
        width: 2em;
        height: 5em;
        line-height: 4em;
        vertical-align: middle;
      }
      .icon-stack-1x,
      .icon-stack-2x,
      .icon-stack-3x {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
      }
      .icon-stack-1x {
        line-height: inherit;
      }
      .icon-stack-2x {
        font-size: 1.5em;
      }
      .icon-stack-3x {
        font-size: 2em;
      }
  .right_align { padding-left:5px; }
    


  .loader-outer {
    display: flex;
    align-items: center;
    justify-content: center;
   height: 100vh;
 
    background: #fff;
  }

  .loader>span {
    width: 9px;
    height: 9px;
    display: block;
    background: #003C7E;
    border-radius: 50%;
    position: relative;
    margin: 0 5px;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dot-1 {
    animation: anim 1s linear 0s infinite;
  }
  
  .dot-2 {
    animation: anim 1s linear 0.25s infinite;
  }
  
  .dot-3 {
    animation: anim 1s linear 0.50s infinite;
  }
  
  .dot-4 {
    animation: anim 1s linear 0.75s infinite;
  }
  
  @keyframes anim {
    0% {
      top: 0;
    }
  
    50% {
      top: 15px;
    }
  
    100% {
      top: 0;
    }
  } 

  .scroll-bar{

    overflow: auto;
    height:600px;
    /* border:1px solid #003C7E; */
  }

  /* width */
::-webkit-scrollbar {
  width: 10px;
  border:1px solid grey;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}



 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #20c997; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5; 
}