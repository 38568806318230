.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px !important;
    height: 24px;
    top: 0 !important;
  }
 
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
 
  .toggle-switch-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: .4s;
    cursor: pointer;
  }
 
  .toggle-switch-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: .4s;
  }
 
  .toggle-switch input:checked + .toggle-switch-slider {
    background-color: #8be78b;
  }
 
  .toggle-switch input:checked + .toggle-switch-slider:before {
    transform: translateX(26px);
  }