.main-container-employee {
    width: 100%;
    display: grid;
    place-items: center;
    padding-top: 30px;
}
.inner-container-employee {
    width: 70%;
    /* border: 1px solid red; */
    padding: 30px 20px;
}
.inner-container-employee .EmployeeName {
    font-size: 22px;
    font-weight: 500;
    color: #003B95;
    margin-bottom: 20px;
}
.empCrt-wise-main {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 4px;
    padding: 0 20px 25px 20px;
    margin: 15px 0;
}
.topEmpItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.innerLeft-topEmpItems {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.innerLeft-topEmpItems img {
    width: 150px;
    border-radius: 10px;
    /* border: 1px solid red;    */
}
.innerLeft-topEmpItems h1 {
    padding-left: 15px;
    font-size: 18px;
    font-weight: 500;
    color: #003B95;
}
.innerRight-topEmpItems p {
    background-color: #E5FFE7;
    color: #1A9B2F;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
}
.orderDetais, .orderDetais-deliverd {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}