.container {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
}

.addButton {
  background-color: #03c9d7;
  color: white;

  /* width: 10%;
  height: 10%;
  table-layout: fixed;
  border-collapse: collapse; */

  border: 2px solid #03c9d7;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}
.addBtn, .order_addBtn {
  padding: 5px 15px;
  color: rgb(0, 60, 126);
  border: 1px solid rgb(0, 60, 126);
  border-radius: 4px;
  cursor: pointer;
}
.addBtn:hover {
  background-color: rgb(0, 60, 126);
  color: white;
}
.order_addBtn {
  margin: 5px 10px 5px 0px;
}
.active_order_addBtn {
  background-color: rgb(0, 60, 126);
  color: white;
}
.e-grid {
  border-width: 0px;  /* 1px */
}
.e-grid .e-headercelldiv {
  font-size: 15px !important;
  color: black !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.backButton {
  background-color: #ff5c8e;
  color: white;

  /* width: 10%;
  table-layout: fixed;
  border-collapse: collapse; */

  border: 2px solid #ff5c8e;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.editButton {
  background-color: #8be78b;
  color: white;
  /* width: 10%;
  table-layout: fixed;
  border-collapse: collapse; */
  border: 2px solid #8be78b;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.backIcon {
  size: 35px;
  color: white;
  background-color: #ff5c8e;
  border-radius: 2px;
  padding: 8px;
}

img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  padding: 5px;
  border-radius: 15px;
  display: inline-block;
}

.block-icon {
  position: relative;
  display: inline-block;
}

.icon-tag {
  color: #ff5c8e;
  background-color: white;
  padding: 2px;
  font-weight: bolder;
  margin: 5px;
  size: 15px;
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 12px !important;
  height: 12px !important;
}

.modal {
  width: 450px;
  margin: auto;
}

.row {
  display: inline-block;
}

.icon-tag {
  color: #ff5c8e;
  background-color: white;
  padding: 0px;
  font-weight: bolder;
  margin: 0px;
  size: 15px;
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 15px !important;
  height: 15px !important;
}

.icon-belowtag {
  color: #ff5c8e;
  background-color: white;
  padding: 0px;
  font-weight: bolder;
  margin: 0px;
  size: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 15px !important;
  height: 15px !important;
}

.scroll-bar{

  overflow: auto;
  height:600px;
  /* border:1px solid #003C7E; */
}

/* width */
::-webkit-scrollbar {
width: 10px;
border:1px solid grey;
}

/* Track */
::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 5px;
}

.bs{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}
.crd h4{color:#66cdaa;}



.pinlbl{
  color:red;
  /* content:"Enter your pincode"; */

}
.text-danger{
  color:red
}

img {
  height: 160px;
  width: 160px;
  object-fit: contain;
  padding: 5px;
  border-radius: 15px;
  display: inline-block;
}

.container {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
}
.table-container {
  height: auto !important;
  max-height: 600px !important;
  overflow-y: auto !important;
  margin-top: 10px;
  /* border: 2px solid rgba(0, 0, 0, 0.12) !important; */
  /* border-radius: 10px !important; */
}
.table-container::-webkit-scrollbar {
  width: 2px;
}
.table-container::-webkit-scrollbar-track { 
  border-radius: 1px;
}
.table-container::-webkit-scrollbar-thumb {
  background: rgb(186, 186, 186); 
}
.myPage {
  color: #3f50b5;
}
.newRecordBtn {
  color: rgb(0, 60, 126) !important;
  border-color: rgb(0, 60, 126) !important;
}
.newRecordBtn:hover {
  transition: all 0.3s ease-in-out;
  background-color: #003c7e !important;
  color: white !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
